import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';

import styles from './index.module.scss';

const FieldInfo = ({ name, info = '', pattern = '' }) => {
  const form = useForm();
  const { error, touched, value } = form.getFieldState(name) || {};
  const { submitFailed } = useFormState() || {};

  if (!info) {
    return null;
  }

  const infoElement = (
    <div className={styles['field-info']} dangerouslySetInnerHTML={{ __html: info }} />
  );
  const showError = error && (submitFailed || (touched && value));
  if (!pattern && !showError) {
    return infoElement;
  }

  return !(showError && touched) ? infoElement : null;
};

FieldInfo.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  pattern: PropTypes.string,
};

export default FieldInfo;
