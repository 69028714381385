import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState, useForm } from 'react-final-form';
import FieldError from '../FieldError';
import FieldInfo from '../FieldInfo';
import ErrorIcon from '../../../../public/static/icons/error.svg';
import QuestionIcon from '../../../../public/static/icons/question-icon.svg';
import CheckmarkIcon from '../../../../public/static/icons/checkmark.svg';
import Tooltip from '../../Tooltip';

const FieldTemplate = ({
  label = '',
  name,
  component,
  children,
  type,
  style = '',
  info = '',
  infoOnPatternNotMatched = '',
  onLabelClick = () => {},
  icon = null,
  textIcon = '',
  placeholder = '',
  isRequired = false,
  tooltip = '',
  pattern = null,
  ...props
}) => {
  const [typedCharacterInPattern, setTypedCharacterInPattern] = useState(true);
  const form = useForm();
  const formState = useFormState();
  const fieldState = form.getFieldState(name);
  const { error, touched, active, value } = fieldState || {};

  const checkKeyAgainstPattern = (ev, setTypedCharacterInPattern, pattern) => {
    const regex = new RegExp(pattern);
    setTypedCharacterInPattern(regex.test(ev.key));
  };

  const classes = ['field', `field-type-${type}`, `field-${name}`];

  // Show error when there's a validation error in the field and
  // either the form was submitted or the field has some value and was
  // focused in and then out.
  const showError = error && (formState.submitFailed || (touched && value));

  if (style.length) {
    classes.push(`field-style-${style}`);
  }

  if (icon) {
    classes.push('with-icon');
  }

  if (showError) {
    classes.push('error');
  }

  if (active) {
    classes.push('active');
  }

  if (value) {
    classes.push('has-value');
  }

  return (
    <div className={classes.join(' ')}>
      {(label || tooltip) && (
        <label htmlFor={name} onClick={onLabelClick}>
          {label}
          {!!label && (
            <span className="validation">{isRequired ? ' (required)' : ' (optional)'}</span>
          )}
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionIcon className="question-icon" />
            </Tooltip>
          )}
        </label>
      )}
      <div style={{ position: 'relative' }}>
        {!!icon && icon}

        {/* This renders the field element input */}
        <Field
          name={name}
          id={name}
          component={component}
          type={type}
          aria-label={label || name}
          aria-required={isRequired ? 'true' : 'false'}
          {...props}
          placeholder={placeholder}
          onKeyPress={
            pattern ? (ev) => checkKeyAgainstPattern(ev, setTypedCharacterInPattern, pattern) : null
          }
        >
          {children}
        </Field>

        {showError && (
          <div className="right-icon-container">
            <ErrorIcon className="error-icon" />
          </div>
        )}

        {!error && touched && value && (
          <div className="right-icon-container">
            <CheckmarkIcon className="checkmark-icon" />
          </div>
        )}

        {!!textIcon && <div className="text-icon">{textIcon}</div>}

        <FieldInfo
          name={name}
          info={typedCharacterInPattern ? info : infoOnPatternNotMatched}
          pattern={props.pattern}
        />

        <FieldError name={name} />
      </div>
    </div>
  );
};

FieldTemplate.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  type: PropTypes.string.isRequired,
  /**
   * Field label.
   */
  label: PropTypes.string,
  /**
   * Field name.
   */
  name: PropTypes.string.isRequired,
  /**
   * Additional CSS classes. If style="normal", then the CSS class will be field-style-normal.
   */
  style: PropTypes.string,
  /**
   * Description text below the field.
   */
  info: PropTypes.string,
  /**
   * Description text below the field when the pattern is not matching.
   */
  infoOnPatternNotMatched: PropTypes.string,
  onLabelClick: PropTypes.func,
  /**
   * Icon component.
   */
  icon: PropTypes.object,
  /**
   * Overlay text label on the right side of the field.
   */
  textIcon: PropTypes.string,
  /**
   * Field placeholder.
   */
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
  /**
   * If provided, text field will display infoOnPatternNotMatched text when the input doesn't match the pattern.
   */
  pattern: PropTypes.string,
};

export default FieldTemplate;
